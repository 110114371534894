








import { Component, Vue } from 'vue-property-decorator';
import { columnsFilter } from './components/tabela';

@Component
export default class ComissoesIndex extends Vue {
  mounted(): void {
    this.$store.commit('SET_TABELA', 'Comissões');
    this.$store.commit('SET_COLUNAS', columnsFilter);
  }
}
